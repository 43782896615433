/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import cookies from 'browser-cookies';

const isDark = typeof document !== 'undefined' && cookies.get('dark-mode') == 'TRUE';

var root = document.getElementsByTagName( 'html' )[0]; // '0' to assign the first (and only `HTML` tag)
root.setAttribute( 'class', isDark ? 'dark' : '' );
